<script setup lang="ts">
  import { useSlots } from 'vue'
  import { useNuxtApp } from '#app'
  import { formatText, sanitize } from '~/utils/format-text'
  import type { PloneDocument } from '~/types/Plone'
  import { useSiteMeta } from '~/composables/useSiteMeta'
  import hasSlotContent from '~/utils/has-slot-content'

  const props = defineProps<{
    data: PloneDocument,
    hideImage?: boolean
    hideText?: boolean
    hideTitle?: boolean
    hideDescription?: boolean
    type?: string
    noindex?: boolean
  }>()

  const app = useNuxtApp()
  const config = useLocaleConfig()
  const slots = useSlots()
  const route = useRoute()
  const { user } = useAuth()
  const img = useImage()
  const { data: site } = useSiteMeta()
  const breadcrumbs = props.data?.['@components']?.breadcrumbs?.items?.map((item: PloneDocument) => ({
    href: item['@id'],
    title: item.title,
  }))

  const hasText = hasSlotContent(slots.text)
  const hasMeta = hasSlotContent(slots.meta)
  const hasToolbar = hasSlotContent(slots.toolbar)
  const hasHeader = (!props.hideImage && props.data.image) || !props.hideTitle || !props.hideDescription || props.data?.hero
  const hasHero = computed(() => !!props.data?.hero)
  const canonicalPath = route.path === '/'
    ? ''
    : props.data?.['@id']?.split('?')[0].replace('++api++/', '')
  const canonicalUrl = `${config.baseURL}${canonicalPath}${route.query.page ? `?page=${route.query.page}` : ''}`
  const pageQuery = route.query.page
    ? isArray(route.query.page)
      ? route.query.page[0]
      : route.query.page
    : null
  // validate if pageQuery is in correct format (e.g. 2-4 or 2)
  const isValidPage = pageQuery?.match(/^[0-9]+(-[0-9]+)?$/)
</script>

<template>
  <article :class="[
    'app-page',
    {
      'app-page--has-breadcrumbs': breadcrumbs?.length > 0,
      'app-page--has-hero': data?.hero
    }]">
    <SchemaOrgWebPage
      v-if="type"
      :type="type"
      :name="data.title"
      :description="data.description || site?.description"/>
    <SchemaOrgWebPage
      v-else
      :name="data.title"
      :description="data.description || site?.description"/>
    <slot name="head">
      <Html lang="cs">
        <Head>
          <Title v-if="route.path === '/'">{{ site?.title }}</Title>
          <Title v-else>
            {{ data.title }}{{ isValidPage ? ` (${route.query.page}. strana)` : '' }} {{ site?.title ? `- ${site.title}` : '' }}
          </Title>
          <Link
            rel="canonical"
            :href="canonicalUrl"/>
          <Meta
            name="robots"
            :content="config.allowIndexing && !route.path.startsWith('/media') && !noindex
              ? 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
              : 'noindex, nofollow'"/>
          <Meta
            name="og:url"
            :content="canonicalUrl"/>
          <Meta
            name="description"
            :content="data.description || $t('description')"/>
          <Meta
            v-if="route.path === '/'"
            name="og:title"
            :content="site?.title"/>
          <Meta
            v-else
            name="og:title"
            :content="`${ data.title } ${site?.title ? `- ${site.title}` : ''}`"/>
          <Meta
            name="og:description"
            :content="data.description || $t('description')"/>
          <Meta
            v-if="data.image"
            name="og:image"
            :content="data.image.download"/>
          <Meta
            v-if="data.hero"
            name="og:image"
            :content="data.hero.download"/>
          <Meta
            v-else
            name="og:image"
            content="/thumbnail.png"/>
        </Head>
      </Html>
    </slot>
    <app-container
      v-if="breadcrumbs?.length > 0 && !data?.hero"
      class="app-page__breadcrumbs-container">
      <div
        class="app-page__breadcrumbs"
        v-if="breadcrumbs?.length > 0">
        <slot name="breadcrumbs">
          <app-breadcrumbs :items="breadcrumbs"/>
        </slot>
      </div>
    </app-container>

    <slot name="messages"/>

    <header
      v-if="hasHeader && hasHero"
      class="app-page__header app-page__header--hero u-mb--500">
      <app-container class="app-page__hero-container">
        <div class="app-page__hero-content">
          <div
            class="app-page__breadcrumbs"
            v-if="breadcrumbs?.length > 0">
            <slot name="breadcrumbs">
              <app-breadcrumbs
                :light="data?.is_hero_content_below"
                :items="breadcrumbs"/>
            </slot>
          </div>
          <div v-if="!data?.is_hero_content_below">
            <slot name="header">
              <div v-if="data?.preTitle" class="u-fs--700">
                {{ formatText(data?.preTitle) }}
              </div>
              <h1 :class="{ 'u-hide-visually': hideTitle }">
                {{ formatText(data?.title) }}
              </h1>
              <slot name="description">
                <h2 v-if="!hideDescription && data.description">
                  {{ formatText(data.description) }}
                </h2>
              </slot>
            </slot>
          </div>
        </div>

        <slot name="hero">
          <div class="app-page__hero">
            <img
              v-if="data.hero.download.endsWith('.svg')"
              :src="data.hero.download"
              :alt="data.title"
              class="app-page__hero-image"/>
            <nuxt-picture
              v-else
              :src="data.hero.download"
              sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
              :width="data.hero.width"
              :height="data.hero.height"
              :alt="data.title"
              quality="85"
              loading="lazy"/>
          </div>
        </slot>
      </app-container>

      <app-container
        v-if="hasMeta"
        narrow>
        <div class="app-page__meta">
          <slot name="meta"/>
        </div>
      </app-container>

      <app-container
        v-if="data?.is_hero_content_below"
        narrow
        class="u-pt--600">
        <slot name="header">
          <div
            v-if="data?.preTitle"
            class="u-fs--700">
            {{ formatText(data?.preTitle) }}
          </div>
          <h1 :class="{ 'u-hide-visually': hideTitle }">
            {{ formatText(data?.title) }}
          </h1>
          <slot name="description">
            <h2 v-if="!hideDescription && data.description">
              {{ formatText(data.description) }}
            </h2>
          </slot>
        </slot>
      </app-container>
    </header>

    <header
      v-else-if="hasHeader"
      class="app-page__header u-mb--500">
      <app-container text>
        <div :class="['app-page__header-layout']">
          <slot name="header">
            <div class="app-page__header-content">
              <div
                v-if="!hideTitle && data?.preTitle"
                class="u-fs--700">
                {{ formatText(data?.preTitle) }}
              </div>
              <h1 v-if="!hideTitle">
                {{ formatText(data?.title) }}
              </h1>
              <slot name="description">
                <h2 v-if="!hideDescription && data.description">
                  {{ formatText(data.description) }}
                </h2>
              </slot>
            </div>
          </slot>
        </div>
      </app-container>

      <app-container narrow v-if="hasToolbar">
        <app-card
          class="app-page__toolbar u-mt--500">
          <slot name="toolbar"></slot>
        </app-card>
      </app-container>

      <app-container
        v-if="hasMeta"
        narrow>
        <div class="app-page__meta">
          <slot name="meta"/>
        </div>
      </app-container>
    </header>

    <app-container
      v-if="hasHeader"
      narrow>
      <template v-if="!hasHero && !hideImage && data.image">
        <slot name="image">
          <app-lightbox
            :images="img(data.image.download, { width: 2560, format: 'webp' })"
            class="u-mb--600">
            <template #default="{ open }">
              <a
                class="app-page__image-link"
                :href="img(data.image.download, { width: 2560, format: 'webp' })"
                @click.prevent="open(1)">
                <figure class="app-page__image-wrap">
                  <nuxt-picture
                    class="app-page__image"
                    :src="data.image.download"
                    sizes="xs:100vw sm:100vw md:60vw lg:60vw xl:60vw xxl:60vw"
                    :width="data.image.width"
                    :height="data.image.height"
                    :alt="data.title"
                    quality="85"
                    loading="lazy"/>
                  <figcaption v-if="data.image_caption">
                    {{ formatText(data.image_caption) }}
                  </figcaption>
                </figure>
              </a>
            </template>
          </app-lightbox>
        </slot>
      </template>
    </app-container>

    <div class="app-page__content">
      <app-container
        v-if="!hideText && (hasText || data?.text)"
        push
        text>
        <div class="app-page__text u-mb--400">
          <slot name="text">
            <div
              v-if="data.text"
              v-html="formatText(sanitize(data.text.data))"></div>
          </slot>
        </div>
      </app-container>
      <slot></slot>
    </div>
    <client-only>
      <lazy-admin-bar
        v-if="user"
        class="u-mt--700"
        :data="data"
        v-motion-fade/>
    </client-only>
  </article>
</template>

<style scoped>
  .app-page {
    padding-top: var(--gap-600);
  }

  .app-page__image-wrap {
    margin: 0;

    & figcaption {
      padding-top: var(--gap-100);
      font-size: var(--font-size-200);
      text-decoration: none;
      color: var(--c-text);
    }
  }

  .app-page__image-link {
    text-decoration: none;
    color: var(--c-text);
  }

  .app-page--has-breadcrumbs {
    padding-top: var(--gap-400);
  }

  .app-page--has-hero {
    padding-top: 0;
  }

  .app-page__breadcrumbs-container {
    @media print {
      display: none;
    }
  }

  .app-page__breadcrumbs {
    margin-bottom: var(--gap-600);
  }

  .app-page__meta {
    font-family: var(--font-family-monospace);
    padding-top: var(--gap-400);
  }

  .app-page__image {
    width: 100%;
    height: auto;
  }


  .app-page__hero-container {
    position: relative;
  }

  .app-page__hero-content {
    position: absolute;
    left: calc(-1 * var(--gap-500));
    right: calc(-1 * var(--gap-500));
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    padding: var(--gap-500) calc(var(--gap-600) + var(--gap-400));

    @media (--md) {
      padding: var(--gap-400) var(--gap-600) calc(var(--gap-600) + var(--gap-500));
    }
  }

  .app-page__hero {
    position: relative;
    z-index: -1;
    left: calc(-1 * var(--gap-500));
    right: calc(-1 * var(--gap-500));
    width: calc(100% + 2 * var(--gap-500));
  }

  .app-page__hero-image {
    width: 100%;
  }
</style>
