import routerOptions0 from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_magicast@0.3.5_optio_c1d1d7bd852d82209db29e61f3d59274/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}