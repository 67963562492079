import { default as _91_46_46_46slug_93yr2yTUNdJDMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/[...slug].vue?macro=true";
import { default as loginjD2BXjAmn4Meta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/forum/login.vue?macro=true";
import { default as indexP7Odkg552gMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/komora/o-komore/logo-ceske-komory-architektu/index.vue?macro=true";
import { default as _91token_93h5dxMiwMa6Meta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/[token].vue?macro=true";
import { default as requestYMgp2ANOTgMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/request.vue?macro=true";
import { default as registerQiz1Ij0v9jMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/register.vue?macro=true";
import { default as searchwvmn76TQDZMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/search.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93yr2yTUNdJDMeta?.name ?? "slug___cs",
    path: _91_46_46_46slug_93yr2yTUNdJDMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93yr2yTUNdJDMeta || {},
    alias: _91_46_46_46slug_93yr2yTUNdJDMeta?.alias || [],
    redirect: _91_46_46_46slug_93yr2yTUNdJDMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93yr2yTUNdJDMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93yr2yTUNdJDMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93yr2yTUNdJDMeta || {},
    alias: _91_46_46_46slug_93yr2yTUNdJDMeta?.alias || [],
    redirect: _91_46_46_46slug_93yr2yTUNdJDMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: loginjD2BXjAmn4Meta?.name ?? "forum-login___cs",
    path: loginjD2BXjAmn4Meta?.path ?? "/forum/login",
    meta: loginjD2BXjAmn4Meta || {},
    alias: loginjD2BXjAmn4Meta?.alias || [],
    redirect: loginjD2BXjAmn4Meta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/forum/login.vue").then(m => m.default || m)
  },
  {
    name: loginjD2BXjAmn4Meta?.name ?? "forum-login___en",
    path: loginjD2BXjAmn4Meta?.path ?? "/forum/login",
    meta: loginjD2BXjAmn4Meta || {},
    alias: loginjD2BXjAmn4Meta?.alias || [],
    redirect: loginjD2BXjAmn4Meta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/forum/login.vue").then(m => m.default || m)
  },
  {
    name: indexP7Odkg552gMeta?.name ?? "komora-o-komore-logo-ceske-komory-architektu___cs",
    path: indexP7Odkg552gMeta?.path ?? "/komora/o-komore/logo-ceske-komory-architektu",
    meta: indexP7Odkg552gMeta || {},
    alias: indexP7Odkg552gMeta?.alias || [],
    redirect: indexP7Odkg552gMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/komora/o-komore/logo-ceske-komory-architektu/index.vue").then(m => m.default || m)
  },
  {
    name: indexP7Odkg552gMeta?.name ?? "komora-o-komore-logo-ceske-komory-architektu___en",
    path: indexP7Odkg552gMeta?.path ?? "/komora/o-komore/logo-ceske-komory-architektu",
    meta: indexP7Odkg552gMeta || {},
    alias: indexP7Odkg552gMeta?.alias || [],
    redirect: indexP7Odkg552gMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/komora/o-komore/logo-ceske-komory-architektu/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93h5dxMiwMa6Meta?.name ?? "passwordreset-token___cs",
    path: _91token_93h5dxMiwMa6Meta?.path ?? "/passwordreset/:token()",
    meta: _91token_93h5dxMiwMa6Meta || {},
    alias: _91token_93h5dxMiwMa6Meta?.alias || [],
    redirect: _91token_93h5dxMiwMa6Meta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93h5dxMiwMa6Meta?.name ?? "passwordreset-token___en",
    path: _91token_93h5dxMiwMa6Meta?.path ?? "/passwordreset/:token()",
    meta: _91token_93h5dxMiwMa6Meta || {},
    alias: _91token_93h5dxMiwMa6Meta?.alias || [],
    redirect: _91token_93h5dxMiwMa6Meta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/[token].vue").then(m => m.default || m)
  },
  {
    name: requestYMgp2ANOTgMeta?.name ?? "passwordreset-request___cs",
    path: requestYMgp2ANOTgMeta?.path ?? "/passwordreset/request",
    meta: requestYMgp2ANOTgMeta || {},
    alias: requestYMgp2ANOTgMeta?.alias || [],
    redirect: requestYMgp2ANOTgMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/request.vue").then(m => m.default || m)
  },
  {
    name: requestYMgp2ANOTgMeta?.name ?? "passwordreset-request___en",
    path: requestYMgp2ANOTgMeta?.path ?? "/passwordreset/request",
    meta: requestYMgp2ANOTgMeta || {},
    alias: requestYMgp2ANOTgMeta?.alias || [],
    redirect: requestYMgp2ANOTgMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/request.vue").then(m => m.default || m)
  },
  {
    name: registerQiz1Ij0v9jMeta?.name ?? "register___cs",
    path: registerQiz1Ij0v9jMeta?.path ?? "/register",
    meta: registerQiz1Ij0v9jMeta || {},
    alias: registerQiz1Ij0v9jMeta?.alias || [],
    redirect: registerQiz1Ij0v9jMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQiz1Ij0v9jMeta?.name ?? "register___en",
    path: registerQiz1Ij0v9jMeta?.path ?? "/register",
    meta: registerQiz1Ij0v9jMeta || {},
    alias: registerQiz1Ij0v9jMeta?.alias || [],
    redirect: registerQiz1Ij0v9jMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: searchwvmn76TQDZMeta?.name ?? "search___cs",
    path: searchwvmn76TQDZMeta?.path ?? "/search",
    meta: searchwvmn76TQDZMeta || {},
    alias: searchwvmn76TQDZMeta?.alias || [],
    redirect: searchwvmn76TQDZMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchwvmn76TQDZMeta?.name ?? "search___en",
    path: searchwvmn76TQDZMeta?.path ?? "/search",
    meta: searchwvmn76TQDZMeta || {},
    alias: searchwvmn76TQDZMeta?.alias || [],
    redirect: searchwvmn76TQDZMeta?.redirect,
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/search.vue").then(m => m.default || m)
  }
]